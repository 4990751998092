import { Component, OnInit, AfterViewInit} from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-sub-container',
  templateUrl: './sub-container.component.html',
  styleUrls: ['./sub-container.component.scss']
})
export class SubContainerComponent implements OnInit, AfterViewInit{
  filteredCommands: string[] = [];
  commonCommands:string[] = ["inquire", "jbconfig", "jbedit", "mminfo", "nsraddadmin", "nsradmin", "nsrauthtrust", "nsrcap", "nsrcapinfo", "nsrck", "nsrdr", "nsrpolicy", "nsrpush", "recover", "save"];
  frequent_commands: string = "FREQUENTLY USED COMMANDS";
  command: string = "";
  url: string = 'https://20221221t093033-dot-networker-commands-prod.el.r.appspot.com/names';
  // url: string = 'https://20221220t104937-dot-nw-command-reference-dev.el.r.appspot.com/names';
  secret: string = 'nvhc6x2h9hpf7ty75xjpeo6v0t2noqyxj3avmqtgwnlanfgz5hx02jv0w9yekbnxb9kp7zwtm1kj2sk2w0o3d1gomdw0ubbkvdcqohwgk7dbmuib14zd7fktjmpwigoh';
  showDetails: boolean = false;
  filterText: string = "";
  constructor(private http: HttpClient) { }

  ngAfterViewInit() {
  (window as any).adsbygoogle = ((window as any).adsbygoogle || []).push({
    google_ad_client: 'YOUR_AD_CLIENT_ID',
    enable_page_level_ads: true
  });
}


  ngOnInit(): void {

    let body: Object = {
      "SECRET": this.secret
    };

    const req = this.http.post<any>(this.url, body)
   req.subscribe(
    {
      next: data => {
        this.filteredCommands = data.data; 
      },
      error: err => {
      }
      
    }

   );
  }

  commandDetails(command: string) {
    this.command = command;
    this.filterText = command;
    this.showDetails = !this.showDetails;
  }

  filterChangeListen() {
      this.showDetails = false;

  }
}
