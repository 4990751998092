<a href="https://crazyrov.com/">
    <div class="row"
        style="background: #AAD4C6; padding-bottom: 10px; padding-top: 10px;border-radius: 2px; border-color: #000;">
        <div class="col-lg-5 ">
            <img class="float-end" src="../assets/crazyrov_logo.png" width="75" height="75">
        </div>
        <div class="col-lg-7 align-self-center">
            <span style="font-size:xx-large; color: #000; text-decoration: none; ">
                <b>CRAZYROV</b>
                <i>STUDIO</i>
            </span>
        </div>
    </div>
</a>
<div class="container" style="width: 80%; min-height: 100%;">
    <div class="row">
        <div class="col-lg-2">
            <img class="float-end" src="../assets/nw_commands_icon.webp" width="150" height="150">

        </div>
        <div class="col-lg-10 align-self-center" style="padding-left: 20px;">
            <p class="float-start h1" style="font-size: 64px; font-family: reem_kufi; font-weight: bold;">
                NETWORKER COMMANDS</p>
        </div>
    </div>
    <div id="content_container">
        <app-sub-container>
        </app-sub-container>
    </div>
</div>

<div class="row">
    <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1891238692513712"
        crossorigin="anonymous"></script>
    <ins class="adsbygoogle" style="display:block" data-ad-format="autorelaxed" data-ad-client="ca-pub-1891238692513712"
        data-ad-slot="7717585481"></ins>
    <script>
        (adsbygoogle = window.adsbygoogle || []).push({});
    </script>
</div>