<div id="sub-container" class="input-group rounded row">
    <div class="col-lg-12 col-sm-12">
        <input type="search" class="form-control rounded" placeholder="Search" aria-label="Search"
            aria-describedby="search-addon" [(ngModel)]="filterText" (input)="filterChangeListen()" />
    </div>
    <!-- <div class="col-sm-1">
        <img src="../assets/search_black_48dp.svg" alt="search">
    </div> -->
    <div class="row" style="height: 50px;"></div>
    <div class="row" style="padding: 0px;">
        <div class="col-lg-8" *ngIf="!showDetails">
            <app-commands (showCommandDetails)="commandDetails($event)" *ngFor="let command of filteredCommands"
                [command]="command" [filterText]="filterText"></app-commands>
        </div>
        <div class="col-lg-8" *ngIf="showDetails">
            <app-command-details (showAllCommands)="commandDetails($event)"
                [command]="filterText"></app-command-details>
        </div>
        <div style="width: 3px;">
            <div class="rounded" style="height: 100%; width: 3px; background: #AAD4C6;"> </div>
        </div>
        <div class="col-lg-3">
            <div class="row">
                <div class="h4">
                    {{ frequent_commands }}
                </div>
                <hr>
                <div class="row">
                    <div style="padding-left: 20px">
                        <app-frequent-commands (showCommandDetails)="commandDetails($event)"
                            *ngFor="let command of commonCommands" [command]="command"></app-frequent-commands>
                    </div>
                </div>
                <hr>
                <div class="row" style="padding-bottom: 10px;">
                    <div class="row">
                        <p class="center">
                            <b>crazyRov Studio</b> is a effort to make NetWorker and Data backup related content to
                            those who are in need of them.
                            I also run a you type channel with the same name that has a number of videos to help
                            understand the different Data protection related concepts.
                            I also have apps on the Google Play store. I also have a blog and other helpful artifacts at
                            www.crazyov.com.
                        </p>
                        <div class="row">
                            <div class="col" style="padding: 10px;">
                                <a href="https://www.youtube.com/channel/UC4JLsVyXMyjbQ4i1NaJj5IQ">
                                    <img src="/assets/youtube.png" style="height: 42px; padding: 10px;">
                                </a>

                                <a href="https://play.google.com/store/apps/dev?id=5757761924161168560">
                                    <img src="/assets/google_play.webp" style="height: 42px; padding: 10px;">
                                </a>
                                <a href="https://crazyrov.com">
                                    <img src="/assets/web_icon.webp" style="height: 42px; padding: 10px;">
                                </a>
                            </div>
                        </div>
                        <div class="row">
                            <p>Please extend your support towards maintaing the infrastructure for crazyRov Studio.
                            </p>
                        </div>
                        <a class="btn btn-primary" href="https://paypal.me/crazyrov?country.x=IN&locale.x=en_GB"
                            role="button"> Donate </a>
                    </div>
                    <hr>
                    <div class="row">
                        <p>Got a suggestion? Write to us admin@crazyrov.com</p>
                    </div>
                    <hr>
                    <div class="row">
                        <div id="adContainer">
                            <ins class="adsbygoogle" style="display:block" data-ad-client="ca-pub-1891238692513712"
                                data-ad-slot="2780283579" data-ad-format="auto" data-full-width-responsive="true"></ins>
                        </div>
                    </div>


                </div>

            </div>


        </div>