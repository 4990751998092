import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-command-details',
  templateUrl: './command-details.component.html',
  styleUrls: ['./command-details.component.scss']
})
export class CommandDetailsComponent implements OnInit {
  @Input() command: string = ""
  @Output() showAllCommands = new EventEmitter<string> ();

  url = "https://20221221t093033-dot-networker-commands-prod.el.r.appspot.com/get-command-details";
  secret: string = 'nvhc6x2h9hpf7ty75xjpeo6v0t2noqyxj3avmqtgwnlanfgz5hx02jv0w9yekbnxb9kp7zwtm1kj2sk2w0o3d1gomdw0ubbkvdcqohwgk7dbmuib14zd7fktjmpwigoh';
  summary: string = '';
  synopsis : string = '';

  commandDetails: any = {};

  constructor(private http: HttpClient) { 
  }

  ngOnInit(): void {
    let body: Object = {
      "SECRET": this.secret,
      "NAME": this.command
    }
    this.http.post<any>(this.url, body).subscribe(
      {
        next: data => {
          Object.entries(data.data).forEach((value, key) => {
            if(value[0] == 'SUMMARY'){
              this.summary = <string>value[1];
              this.summary = this.summary.replace(/(?:\r\n|\r|\n)/g, '<br>');
            } else if(value[0] == 'SYNOPSIS') {
              this.synopsis = <string>value[1];
              this.synopsis = this.synopsis.replace(/(?:\r\n|\r|\n)/g, '<br>');
            } else {
              this.commandDetails[value[0]] = (<string>value[1]).replace(/(?:\r\n|\r|\n)/g, '<br>');
          }
        }        
          );
          delete this.commandDetails.NAME;

        }
      }
    )
  }

  emitAllCommands(){
  this.showAllCommands.emit("")
  }

}
