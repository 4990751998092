import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms'
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServerComponent } from './server/server.component';
import { SubContainerComponent } from './sub-container/sub-container.component';
import { CommandsComponent } from './commands/commands.component';
import { HttpClientModule } from '@angular/common/http';
import { CommandDetailsComponent } from './command-details/command-details.component';
import { FrequentCommandsComponent } from './frequent-commands/frequent-commands.component';


@NgModule({
  declarations: [
    AppComponent,
    ServerComponent,
    SubContainerComponent,
    CommandsComponent,
    CommandDetailsComponent,
    FrequentCommandsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
