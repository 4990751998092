import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';


@Component({
  selector: 'app-commands',
  templateUrl: './commands.component.html',
  styleUrls: ['./commands.component.scss']
})
export class CommandsComponent implements OnInit {
  @Input() command: string = "";
  @Input() filterText: string = "";
  @Output() showCommandDetails = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }

  getCommandDetails(){
    this.showCommandDetails.emit(this.command)
  }

}
