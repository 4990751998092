<!-- <button class="btn btn-primary" (click)="emitAllCommands()"> Load all</button> -->

<div class="card" style="width: 100%;">
    <div class="card-body">
        <h2 class="card-title">{{command}}</h2>
        <hr>
        <h6 class="card-subtitle mb-2 text-muted">
            <div [innerHTML]="summary"></div>

        </h6>
    </div>
</div>

<div class="card" style="width: 100%;">
    <h4 class="card-header">
        SYNOPSIS
    </h4>
    <div class="card-body">
        <h6 class="card-text">
            <div [innerHTML]="synopsis"></div>

        </h6>
    </div>
</div>


<div  *ngFor="let commandDetail of commandDetails | keyvalue"  class="card" style="width: 100%;">
    <h4 class="card-header">
        {{commandDetail.key}}
    </h4>
    <div class="card-body">
        <h6 class="card-text">
            <div [innerHTML]="commandDetail.value"></div>

        </h6>
    </div>
</div>